<template>
  <v-container class="deposit">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col class="pl-8">
          <v-col class="px-0">
            <v-card class="transfer-deposit" dark>
              <v-card-title class="transfer-deposit-text d-flex justify-center"
                >My Balance</v-card-title
              >
              <v-card-text class="transfer-deposit-text"
                >₱{{
                  partnerprofile && partnerprofile.partner_credits | formatMoney
                }}</v-card-text
              >
            </v-card>
          </v-col>
          <v-col class="px-0">
            <v-card class="transfer-deposit" dark>
              <v-card-title class="transfer-deposit-text d-flex justify-center"
                >My Total-Available</v-card-title
              >
              <v-card-text class="transfer-deposit-text"
                >₱{{ totalBalance | formatMoney }}</v-card-text
              >
            </v-card>
          </v-col>
        </v-col>
        <v-col class="pr-8">
          <v-col class="px-0">
            <v-card class="transfer-deposit" dark>
              <v-card-title class="transfer-deposit-text d-flex justify-center"
                >User Balance</v-card-title
              >
              <v-card-text class="transfer-deposit-text">&#8369;{{
                formatNumber(userBalance)
              }}</v-card-text>
            </v-card>
          </v-col>
        </v-col>
      </v-row>
      <v-row class="px-5">
        <v-col>
          <v-autocomplete
            clearable
            outlined
            v-model="selectPlayer"
            :items="players_list"
            label="Users"
            :rules="partnerUserRules"
            required
            rounded
            hide-details
            class="transfer-deposit-input"
            :no-filter="true"
            :item-text="combinedText"
            item-value="id"
            @keyup="searchPlayer"
            @click:clear="clearSearch"
          >
            <template v-slot:item="data">
              <template>
                <v-row>
                  <v-col cols="2" class="px-0" style="align-self: center">
                    <v-chip
                      x-small
                      :color="getColor(data.item.type)"
                      class="tw-text-xs"
                      >{{ getInitial(data.item.type) }}</v-chip
                    >
                  </v-col>
                  <v-col cols="10" class="text-start">
                    <span class="tw-text-xs">
                      {{data.item.partner}} - {{ data.item.name }}
                    </span>
                    <br />
                    <span class="tw-text-xs">
                      {{ data.item.email }}
                    </span>
                  </v-col>
                </v-row>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col>
          <v-text-field
            :rules="amountRules"
            label="Amount"
            v-model="amount"
            outlined
            required
            rounded
            class="text-field-amount"
          ></v-text-field>
        </v-col>
        <v-col class="pt-0" cols="12">
          <v-textarea
            v-model="comment"
            outlined
            name="input-7-4"
            label="Comment"
            class="transfer-deposit-input"
            hide-details
          ></v-textarea>
        </v-col>
      </v-row>
      <v-card-actions class="transfer-deposit-text d-flex justify-center py-10">
        <v-btn color="white" text @click="closeDialog"> CANCEL </v-btn>
          <v-btn
          color="#45D0D2"
          :disabled="isDisabled"
          rounded
          @click="openConfirmation"
          class="px-5"
        >
          TRANSFER
        </v-btn>
      </v-card-actions>
        <v-dialog
				v-model="confirmDialog"
				@click:outside="closeDialog"
        @keydown.esc="closeDialog"
				height="400"
				width="400"
				>
					<v-card class="card">
						<v-col cols="12" class="pt-10 d-flex justify-center">
							<v-img max-height="75" max-width="75" :src="image"></v-img>
						</v-col>
						<v-col cols="12" class="d-flex justify-center pb-0">
							<v-card-title class="message-header font-weight-bold">
								ARE YOU SURE?
							</v-card-title>
						</v-col>
						<v-col cols="12" class="d-flex justify-center pt-0">
							<v-card-title class="message-header text-caption pt-0">
								<span class="text-wrap" style="width: 100%">Are you sure to</span>
								<span class="text-wrap" style="width: 100%"
								>proceed the Transaction?</span
								>
							</v-card-title>
						</v-col>
						<v-col cols="12" class="d-flex justify-center">
							<v-card-actions class="pb-10">
								<v-spacer></v-spacer>
								<v-btn
								class="dialog-btn"
								color="success"
								rounded
								@click="transferDeposit"
								>
								YES
								</v-btn>
								<v-btn
								class="dialog-btn"
								color="error"
								rounded
								@click="closeconfirmDialog"
								>
								NO
								</v-btn>
							</v-card-actions>
						</v-col>
					</v-card>
				</v-dialog>
    </v-form>
    <Alert
      :alert_dialog="alert_dialog"
      :alert_success="alert_success"
      :alert_message="alert_message"
      @closeAlertDialog="closeAlertDialog"
    />
  </v-container>
</template>
<script>
import { mapActions, mapState } from "vuex";
import Alert from "../../../dashboard/modal/Alert.vue";
import numeral from "numeral" 
import Question from "@/assets/images/alert/question.svg";
import { useDebounceFn } from "@vueuse/core";

export default {
  props:["viewDialog","transferMoneyDialog"],
  data() {
    return {
      items: ["Foo", "Bar", "Fizz", "Buzz"],
      selectPartnerUser: "",
      selectedUser: "",
      agentDetails: [],
      amount: "",
      alert_dialog: false,
      alert_success: "",
      alert_message: "",
      valid: true,
      comment: "",
      amountRules: [
        (amount) => !!amount || "Amount is required",
        (amount) => (amount && amount >= 100) || "Minimum of 100",
      ],
      partnerUserRules: [
        (selectPartnerUser) =>
          !!selectPartnerUser || "Transaction type is required",
      ],
      commentRules: [(comment) => !!comment || "Transaction type is required"],
      confirmDialog: false,
			image: Question,
      curPage: 1,
      default_item_per_page: 50,
      search: "",
      players_list: "",
      selectPlayer: "",
      userBalance: "",
      fetchDebounce: useDebounceFn(async () =>{
         await this.getPartnerSubUsers({
          search: this.selectPartnerUser || "",
          id: this.partnerprofile.id,
        });
         let subplayer =
            this.partnersubuser &&
            this.partnersubuser.map((data) => {
              console.log("dataaaaa brian", data);
              // alert('x')
              return {
                id: data && data.id,
                name:
                  data &&
                  data.name.charAt(0).toUpperCase() + data.name.slice(1),
                partner:
                  data.registeredPartner.partner_name.charAt(0).toUpperCase() +
                  data.registeredPartner.partner_name.slice(1),
                credits: data && data.credits,
                type: data && data.registeredPartner.type,
                email: data && data.email,
              };
            });
          this.players_list = subplayer;
      }),
      isDisabled: false,
    };
  },
  components: {
    Alert,
  },
  computed: {
    ...mapState("partner", [
      "partneruser",
      "partnersubuser",
      "transfertransaction",
      "partnerprofile"
    ]),
    ...mapState("user", ["auth_user"]),
		...mapState("admin", ["adminpartnersubpartners"]),
    totalBalance() {
      let partner_credits =
        this.partnerprofile && this.partnerprofile.partner_credits;
      let total = partner_credits;
      return total;
    },
    userBalance() {
      let balance = this.selectedUser
        ? this.selectedUser.credits | this.formatMoney
        : "-";
      return balance;
    },
    combinedText() {
      return (item) => `${item.name} - ${item.email}`;
    },
    
  },
  methods: {
    ...mapActions("partner", [
      "partnerUsers",
      "getPartnerSubUsers",
      "getPartnerProfileData",
      "postTransferTransaction",
      "getPartnerUserInfo"
    ]),
    ...mapActions("user", ["getUserInfo"]),
		...mapActions("admin", ["getPartnerSubPartners"]),
    ...mapActions("user", ["getSubUser"]),
    closeDialog() {
      this.$emit("closeDialog", true);
    },
    closeconfirmDialog(){
      this.isDisabled = false;
			this.confirmDialog = false;
		},
    async transferDeposit() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        const res = await this.getSubUser(this.$auth.user.id)
        await this.postTransferTransaction({
          user_id: this.selectPlayer,
          type: "deposit",
          amount: this.amount,
          partner_id: res.id,
          status: "completed",
          transaction_type: "direct",
          
        });
        if (this.transfertransaction.updated) {
          this.closeDialog();
          const res = await this.getSubUser(this.$auth.user.id)
          await this.getPartnerProfileData({
            id: res.user_id,
          });
          await this.getUserInfo(res.user_id);
					await this.getPartnerSubPartners({id : res.id});
          await this.searchData();
          this.$refs.form.reset();
        }
        this.alert_dialog = true;
        this.alert_success = this.transfertransaction.updated;
        this.alert_message = !this.alert_success ? this.transfertransaction.message : "CREDITS TRANSFERRED SUCCESSFULLY";
      }
    },
    closeAlertDialog() {
      this.isDisabled = false;
      this.alert_dialog = false;
      this.selectPartnerUser = "";
    },
    formatNumber(value){
      return numeral(value).format('0,0.00')
    },
    openConfirmation(){
			this.$refs.form.validate();
			if (this.$refs.form.validate()) {
        this.isDisabled = true;
				this.confirmDialog = true
			}
		},
    async searchData(item) {
        const res = await this.getSubUser(this.$auth.user.id);
        await this.getPartnerSubUsers({
            search: this.search || "",
            id: res.id,
        });
    },
    getInitial(type){
        if(type == 'partners')
            return 'MP';
        else if(type == 'area-manager')
            return 'AM';
        else if (type == "city-manager")
            return 'CM';
        else{
            return 'A';
        }
    },
    getColor(type){
      if (type == "partners") return "success";
      else if (type == "area-manager") return "warning";
      else if (type == "city-manager") return "primary";
      else {
        return "error";
      }
    },
     async clearSearch() {
      this.selectPartnerUser = "";
      await this.searchData();
      await this.getAllPlayers();
      
    },
    searchPlayer(e) {
      this.selectPartnerUser = e.target.value;
      this.fetchDebounce();
    },
    async getAllPlayers() {
        let subplayer =
          this.partnersubuser &&
          this.partnersubuser.map((data) => {
            return {
              id: data && data.id,
              name:
                data && data.name.charAt(0).toUpperCase() + data.name.slice(1),
              partner:
                data.registeredPartner.partner_name.charAt(0).toUpperCase() +
                data.registeredPartner.partner_name.slice(1),
              credits: data && data.credits,
              type: data && data.registeredPartner.type,
              email: data && data.email,
            };
          });
        this.players_list = subplayer;
      },
    async depositData(){
      const res = await this.getSubUser(this.$auth.user.id);
      await this.searchData();
      await this.getPartnerProfileData({ id: res.user_id });
      await this.getUserInfo(res.user_id);
      await this.getPartnerSubPartners({id : res.id});
      await this.getAllPlayers();
      this.partnerprofile = res;
    }
  },
  async mounted() {
     if (this.transferMoneyDialog && this.viewDialog === "deposit") {
      console.log("Mounted with Deposit tab active.");
      this.depositData();
    }
  },
  watch: {
   viewDialog(newVal) {
      if (this.transferMoneyDialog && newVal === "deposit") {
        console.log("Switched to Deposit tab.");
        this.depositData();
      }
    },
    transferMoneyDialog(newVal) {
      if (newVal && this.viewDialog === "deposit") {
        console.log("Dialog reopened with Deposit tab active.");
        this.depositData();
      }
    },
    async selectPlayer(value) {
      let selected_player = await this.getPartnerUserInfo(value);
      this.userBalance = selected_player && selected_player.credits;
      console.log("value", value);
    },
  },
};
</script>
<style>
.text-field-amount .v-input__control .v-input__slot {
  background: #222f3a;
}
.deposit {
  background: #0d111b !important;
}
.transfer-deposit-input {
  background: #222f3a !important;
}
.transfer-deposit {
  background: #222f3a !important;
  border-radius: 10px !important;
}
.transfer-deposit-text {
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  /* identical to box height */

  text-align: center !important;

  color: #ffffff !important;
}
</style>
<style scoped>
.card {
	border-radius: 25px !important;
	background-color: #0d111b !important;
}
.dialog-btn {
	width: 100px;
	height: 36px;
}
.v-select-list { 
  margin-top: 5px;
  background: #141c2e !important;
  border: 1px solid #d1d5db !important;
  border-radius: 6px !important;
}
</style>