<template>
  <v-dialog v-model="viewDialog" width="700"  @keydown.esc="closeDialog" @click:outside="closeDialog">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card class="add-user">
        <div class="tw-text-right tw-pr-4 tw-pt-3">
          <v-icon @click="closeDialog" color="red">mdi-close</v-icon>
        </div>
        <v-card-title class="text-h6 d-flex justify-center pb-10 px-10 tw-pt-0">
          Add Player 
        </v-card-title>
        <v-card-actions class="pa-3">
          <v-row>
            <v-col cols="12" lg="6" md="6" class="px-7">
              <v-text-field
                label="Name"
                placeholder="Name"
                outlined
                hide-details
                v-model="name"
                dense
                :rules="nameRules"
                required
                class="add-user-input"
              ></v-text-field>
              <div class="tw-text-left">
                <span :class="`tw-text-xs ${check_name.success ? 'tw-text-green-500' : 'tw-text-red-500'}`">
                  {{check_name.message}}
                </span>
              </div>
            </v-col>
            <v-col cols="12" lg="6" md="6" class="px-7">
              <v-text-field
                label="Email"
                placeholder="Email"
                outlined
                hide-details
                type="email"
                v-model="email"
                dense
                :rules="emailRules"
                required
                class="add-user-input"
              ></v-text-field>
              <div class="tw-text-left">
                <span :class="`tw-text-xs ${check_email.success ? 'tw-text-green-500' : 'tw-text-red-500'}`">
                  {{check_email.message}}
                </span>
              </div>
            </v-col>
            <v-col cols="12" lg="6" md="6" class="px-7">
              <v-text-field
                label="Password"
                placeholder="Password"
                outlined
                hide-details
                v-model="password"
                dense
                :rules="passwordRules"
                required 
                type="password"
                class="text-field-amount"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" md="6" class="px-7">
              <v-text-field
                label="Confirm Password"
                placeholder="Confirm Password"
                outlined
                dense
                v-model="password_confirmation"
                :rules="password_confirmationRules"
                required
                type="password"
                class="text-field-amount"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" md="6" class="px-7 pt-0">
              <v-text-field
                outlined
                dense
                hide-details
                v-model="partner"
                disabled
                class="add-user-input"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-actions class="d-flex justify-center py-10">
          <v-btn color="white" text @click="closeDialog"> CANCEL </v-btn>
          <v-btn color="#45D0D2" rounded :disabled="isDisabled"  @click="registerUser" class="px-5"> REGISTER </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <Alert
      :alert_dialog="alert_dialog"
      :alert_success="alert_success"
      :alert_message="alert_message"
      @closeAlertDialog="closeAlertDialog"
    />
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { useDebounceFn } from "@vueuse/core";
import Alert from "../modal/Alert.vue";
export default {
  props: ["addUserDialog"],
  data() {
    return {
      items: ["Foo", "Bar", "Fizz", "Buzz"],
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
      partner: "",
      alert_dialog: false,
      alert_success: "",
      alert_message: "",
      valid: true,
      nameRules: [
        (name) => !!name || "Name is required" ],
      emailRules: [
        (email) => !!email || "E-mail is required",
        (email) => !email || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email) || "E-mail must be valid",
      ],
      passwordRules: [
        (password) => !!password || "Password is required",
        (password) =>
          password && password.length <= 10 || "Password must be less than 6 characters",
      ],
      password_confirmationRules: [
        (password_confirmation) =>
          !!password_confirmation || "Password confirmation is required",
        (password_confirmation) =>
          password_confirmation && password_confirmation.length <= 10 ||
          "Password Confirmation must be less than 6 characters",
        // (password_confirmation) =>
        //   password_confirmation === this.password || 'The password confirmation does not match.'
      ],
      partnerRules: [(partner) => !!partner || "Name is required"],
      debouncedName: useDebounceFn(async () => {
        await this.checkNameResult()
      }, 1000),
      debouncedEmail: useDebounceFn(async () => {
        await this.checkEmailResult()
      }, 1000),
      check_name : "",
      check_email : "",
      isDisabled: false,
      ip_address: ""
    };
  },
  components: {
    Alert,
  },
  computed: {
    ...mapState("partner", ["partnerprofile", "adduser"]),
    viewDialog() {
      return this.addUserDialog;
    },
    partnerList() {
      const partner = [
        {
          partner_name: this.partnerprofile && this.partnerprofile.partner_name,
          id: this.partnerprofile && this.partnerprofile.id,
        },
      ];
      return partner;
    },
  },
  methods: {
    ...mapActions("partner", ["getPartnerProfileData", "postAddUser","checkUserInfo"]),
    ...mapActions("user", ["getSubUser","getUserIpAddress"]),
    async closeDialog() {
      this.$emit("closeDialog", true);
      if (!!this.$cookies.get("is_subuser")) {
        const res = await this.getSubUser(this.$auth.user.id);
      }
      this.partner = !!this.$cookies.get("is_subuser") ? res.partner_name : this.partnerprofile.partner_name;
      this.name =  "";
      this.email =  "";
      this.password =  "";
      this.password_confirmation =  "";
      this.nameRules = false;
      this.emailRules = false;
      this.passwordRules = false;
      this.password_confirmationRules = false;
      this.check_name = ""
      this.check_email = ""
    },
    async closeAlertDialog() {
      this.isDisabled = false;
      this.alert_dialog = false;
      if (!!this.$cookies.get("is_subuser")) {
        const res = await this.getSubUser(this.$auth.user.id);
      }
      this.partner = !!this.$cookies.get("is_subuser") ? res.partner_name : this.partnerprofile.partner_name;
    },
    async registerUser() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        if (!!this.$cookies.get("is_subuser")) {
          const res = await this.getSubUser(this.$auth.user.id);
          await this.postAddUser({
          name: this.name,
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
          partner_id: !!this.$cookies.get("is_subuser") ? res.id : this.partnerprofile.id,
          is_partner : false,
          ip_address : this.$auth.user.last_logged_in_ip
        });

         if (this.adduser.success) {
          this.closeDialog();
          this.$refs.form.reset();
          this.isDisabled = false;
        }
        this.alert_dialog = true;
        this.alert_success = this.adduser.success;
        this.alert_message = !this.alert_success
          ? this.adduser.message
          : "NEW PLAYER ADDED SUCCESSFULLY";
        }
        // this.isDisabled = true;
        // console.log('asdasdasdasdasdas',res)
        await this.postAddUser({
          name: this.name,
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
          partner_id: !!this.$cookies.get("is_subuser") ? res.id : this.partnerprofile.id,
          is_partner : false,
          ip_address : this.$auth.user.last_logged_in_ip
        });
        if (this.adduser.success) {
          this.closeDialog();
          this.$refs.form.reset();
          this.isDisabled = false;
        }
        this.alert_dialog = true;
        this.alert_success = this.adduser.success;
        this.alert_message = !this.alert_success
          ? this.adduser.message
          : "NEW PLAYER ADDED SUCCESSFULLY";
          
      }
    },
    async checkNameResult(){
      if(this.name.length < 5) {
        this.check_name = {
          message: "The name must contain at least 5 characters"
        }
      }else{
        const res = await this.checkUserInfo({
          name : this.name,
        });
        this.check_name = res.data && res.data
        this.isDisabled = !this.check_name.success;
      }
    },
    async checkEmailResult(){
      if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)){
         const res = await this.checkUserInfo({
          email : this.email,
        });
        this.check_email = res.data && res.data
        this.isDisabled = !this.check_email.success;
        
      }else{
         this.check_email = {
          message: "Please enter a valid email address"
        }
      }
     
    },
  },
  async mounted() {
    if (!!this.$cookies.get("is_subuser")) {
      const res = await this.getSubUser(this.$auth.user.id);
      this.partner = res.partner_name;
    }else{
    this.partner = this.partnerprofile.partner_name;
    }
  },
  watch:{
    name(value){
      if (value.length != 0) {
        this.debouncedName()
      }
    },
    email(value){
      if (value.length != 0) {
        this.debouncedEmail()
      }
    },
  }
};
</script>
<style>
.add-user{
  background: #0D111B !important;
  border-radius: 20px !important;
}.add-user-input{
  border-radius: 5px;
  border: 0;
  background: #222F3A;
}
.text-field-amount .v-input__control .v-input__slot{
	background: #222f3a;
}

</style>