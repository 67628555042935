<template>
  <div v-if="!$vuetify.breakpoint.mobile">
    <div class="" v-if="isHeader">
      <draggable
        v-if="$auth.user && ['admin', 'super-admin'].includes($auth.user.type)"
        v-model="providerHolder"
        ghost-class="ghost"
        :move="checkMove"
        @start="onDragStart"
        @end="onDragEnd"
        class="tw-grid tw-grid-cols-2 tw-gap-4 tw-p-4"
      >
        <div
          v-for="(item, i) in providerHolder"
          :key="i"
          @click="selectProvider(item.provider)"
          :class="`tw-border tw-border-transparent tw-rounded-lg  tw-min-w-[120px] tw-p-2 tw-px-8 ${
            selectedProvider.includes(item.provider)
              ? 'tw-bg-[#E4682D]'
              : 'tw-bg-[#2C90E3]'
          } tw-cursor-pointer`"
        >
          <img
            v-if="item.provider != 'All'"
            class="tw-h-[35px] tw-w-[100%] tw-object-contain tw-text-black tw-font-extrabold tw-uppercase"
            :src="`../provider_img_white/${item.provider}.png`"
            :alt="item.provider"
          />
          <div
            v-else
            class="tw-h-[20px] tw-inline-block tw-content-center tw-text-2xl tw-font-extrabold tw-uppercase tw-text-black"
          >
            {{ item.provider }}
          </div>
        </div>
      </draggable>
      <div class="tw-grid tw-grid-cols-2 tw-gap-4 tw-p-4" v-else>
        <div
          v-for="(item, i) in providerHolder"
          :key="i"
          @click="selectProvider(item.provider)"
          :class="`tw-border tw-border-transparent tw-rounded-lg  tw-min-w-[120px]  tw-p-2 tw-px-8 ${
            selectedProvider.includes(item.provider)
              ? 'tw-bg-[#E4682D]'
              : 'tw-bg-[#2C90E3]'
          } tw-cursor-pointer`"
        >
          <img
            v-if="item.provider != 'All'"
            class="tw-h-[35px] tw-w-[100%] tw-object-contain tw-text-black tw-font-extrabold tw-uppercase"
            :src="`../provider_img_white/${item.provider}.png`"
            :alt="item.provider"
          />
          <div
            v-else
            class="tw-h-[20px] tw-inline-block tw-content-center tw-text-2xl tw-font-extrabold tw-uppercase tw-text-black"
          >
            {{ item.provider }}
          </div>
        </div>
      </div>
    </div>
    <v-card
      v-else
      rounded="lg"
      class="tw-rounded-lg tw-p-4 tw-py-4"
      color="#161C2C"
    >
      <div
        :class="`tw-flex ${
          provider_exapanded ? 'tw-items-start' : 'tw-items-center'
        } tw-gap-2`"
      >
        <draggable
          v-if="
            $auth.user && ['admin', 'super-admin'].includes($auth.user.type)
          "
          v-model="providerHolder"
          ghost-class="ghost"
          :move="checkMove"
          @start="onDragStart"
          @end="onDragEnd"
          class="tw-flex-auto tw-grid tw-grid-cols-7 tw-items-center tw-gap-6"
        >
          <div
            v-for="(provider, i) in providerHolder"
            :key="i"
            @click="selectProvider(provider.provider)"
            :class="`tw-border tw-border-transparent tw-rounded-lg tw-w-[100%] tw-p-2 tw-px-8 ${
              selectedProvider.includes(provider.provider)
                ? 'tw-bg-[#E4682D]'
                : 'tw-bg-[#2C90E3]'
            } tw-cursor-pointer`"
          >
            <!-- <v-img
           :src="`../provider_img_white/${provider.provider}.png`"
           max-width="150"
           max-height="150"
         ></v-img> -->
            <img
              v-if="provider.provider != 'All'"
              class="tw-h-[35px] tw-w-[100%] tw-object-scale-down tw-text-black tw-font-extrabold tw-uppercase tw-text-sm"
              :src="`../../provider_img_white/${provider.provider}.png`"
              :alt="provider.provider"
            />
            <div
              v-else
              class="tw-h-[35px] tw-inline-block tw-content-center tw-text-2xl tw-font-extrabold tw-uppercase tw-text-black"
            >
              {{ provider.provider }}
            </div>
            <!-- <div v-else class="tw-text-black tw-font-extrabold tw-uppercase">
         {{ provider.provider }}
       </div> -->
          </div>
        </draggable>
        <div
          v-else
          class="tw-flex-auto tw-grid tw-grid-cols-7 tw-items-center tw-gap-6"
        >
          <div
            v-for="(provider, i) in providerHolder"
            :key="i"
            @click="selectProvider(provider.provider)"
            :class="`tw-border tw-border-transparent tw-rounded-lg tw-p-2 tw-w-[100%] tw-px-8 ${
              selectedProvider.includes(provider.provider)
                ? 'tw-bg-[#E4682D]'
                : 'tw-bg-[#2C90E3]'
            } tw-cursor-pointer`"
          >
            <!-- <v-img
           :src="`../provider_img_white/${provider.provider}.png`"
           max-width="150"
           max-height="150"
         ></v-img> -->
            <img
              v-if="provider.provider != 'All'"
              class="tw-h-[35px] tw-w-[100%] tw-object-contain tw-text-black tw-font-extrabold tw-uppercase"
              :src="`../provider_img_white/${provider.provider}.png`"
              :alt="provider.provider"
            />
            <div
              v-else
              class="tw-h-[35px] tw-inline-block tw-content-center tw-text-2xl tw-font-extrabold tw-uppercase tw-text-black"
            >
              {{ provider.provider }}
            </div>
            <!-- <div v-else class="tw-text-black tw-font-extrabold tw-uppercase">
         {{ provider.provider }}
       </div> -->
          </div>
        </div>
        <div class="tw-flex-none tw-py-2" v-if="providers.length > 7">
          <v-icon @click="expandProviderList">{{
            provider_exapanded ? "mdi-arrow-up" : "mdi-arrow-down"
          }}</v-icon>
        </div>
      </div>
    </v-card>
  </div>

  <div class="tw-px-4" v-else>
    <div class="" v-if="isHeader">
      <draggable
        v-if="$auth.user && ['admin', 'super-admin'].includes($auth.user.type)"
        v-model="providerHolder"
        ghost-class="ghost"
        :move="checkMove"
        @start="onDragStart"
        @end="onDragEnd"
        class="tw-grid tw-grid-cols-2 tw-gap-4 tw-p-4"
      >
        <div
          v-for="(item, i) in providerHolder"
          :key="i"
          @click="selectProvider(item.provider)"
          :class="`tw-border tw-border-transparent tw-rounded-lg  tw-min-w-[120px] tw-p-2 tw-px-8 ${
            selectedProvider.includes(item.provider)
              ? 'tw-bg-[#E4682D]'
              : 'tw-bg-[#2C90E3]'
          } tw-cursor-pointer`"
        >
          <img
            v-if="item.provider != 'All'"
            class="tw-h-[35px] tw-w-[100%] tw-object-contain tw-text-black tw-font-extrabold tw-uppercase"
            :src="`../provider_img_white/${item.provider}.png`"
            :alt="item.provider"
          />
          <div
            v-else
            class="tw-h-[20px] tw-inline-block tw-content-center tw-text-2xl tw-font-extrabold tw-uppercase tw-text-black"
          >
            {{ item.provider }}
          </div>
        </div>
      </draggable>
      <div class="tw-grid tw-grid-cols-2 tw-gap-4 tw-p-4" v-else>
        <div
          v-for="(item, i) in providerHolder"
          :key="i"
          @click="selectProvider(item.provider)"
          :class="`tw-border tw-border-transparent tw-rounded-lg  tw-min-w-[120px] tw-p-2 tw-px-8 ${
            selectedProvider.includes(item.provider)
              ? 'tw-bg-[#E4682D]'
              : 'tw-bg-[#2C90E3]'
          } tw-cursor-pointer`"
        >
          <img
            v-if="item.provider != 'All'"
            class="tw-h-[35px] tw-w-[100%] tw-object-contain tw-text-black tw-font-extrabold tw-uppercase"
            :src="`../provider_img_white/${item.provider}.png`"
            :alt="item.provider"
          />
          <div
            v-else
            class="tw-h-[20px] tw-inline-block tw-content-center tw-text-2xl tw-font-extrabold tw-uppercase tw-text-black"
          >
            {{ item.provider }}
          </div>
        </div>
      </div>
    </div>
    <div v-else class="tw-overflow-x-auto tw-whitespace-nowrap tw-no-scrollbar">
      <div class="tw-flex tw-space-x-4">
        <draggable
          v-if="
            $auth.user && ['admin', 'super-admin'].includes($auth.user.type)
          "
          v-model="providerHolder"
          ghost-class="ghost"
          :move="checkMove"
          @start="onDragStart"
          @end="onDragEnd"
          class="tw-flex tw-space-x-4"
        >
          <div
            v-for="(item, i) in providerHolder"
            :key="i"
            @click="selectProvider(item.provider)"
            :class="`tw-border tw-border-transparent tw-rounded-lg tw-py-2  tw-min-w-[120px] tw-flex tw-items-center tw-justify-center ${
              selectedProvider.includes(item.provider)
                ? 'tw-bg-[#E4682D]'
                : 'tw-bg-[#2C90E3]'
            } tw-cursor-pointer`"
          >
            <img
              v-if="item.provider != 'All'"
              class="tw-h-[45px] tw-w-[92px] tw-object-contain"
              :src="`../provider_img_white/${item.provider}.png`"
              :alt="item.provider"
            />
            <div
              v-else
              class="tw-h-[20px] tw-inline-block tw-content-center tw-text-2xl tw-font-extrabold tw-uppercase tw-text-black"
            >
              {{ item.provider }}
            </div>
          </div>
        </draggable>
        <div class="tw-flex tw-space-x-4" v-else>
          <div
            v-for="(item, i) in providerHolder"
            :key="i"
            @click="selectProvider(item.provider)"
            :class="`tw-border tw-border-transparent tw-rounded-lg  tw-py-2  tw-min-w-[120px] tw-flex tw-items-center tw-justify-center ${
              selectedProvider.includes(item.provider)
                ? 'tw-bg-[#E4682D]'
                : 'tw-bg-[#2C90E3]'
            } tw-cursor-pointer`"
          >
            <img
              v-if="item.provider != 'All'"
              class="tw-h-[45px] tw-w-[92px] tw-object-contain"
              :src="`../provider_img_white/${item.provider}.png`"
              :alt="item.provider"
            />
            <div
              v-else
              class="tw-h-[20px] tw-inline-block tw-content-center tw-text-2xl tw-font-extrabold tw-uppercase tw-text-black"
            >
              {{ item.provider }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import { EventBus } from "@/plugins/eventBus";

export default {
  props: ["providers", "type", "isHeader"],
  data: () => ({
    selectedProvider: [],
    providerHolder: [],
    provider_exapanded: false,
    dragging: false,
  }),
  components: {
    draggable,
  },
  watch: {
    providers() {
      if (this.$vuetify.breakpoint.mobile || this.isHeader) {
        this.providerHolder = this.providers;
      } else {
        this.providerHolder = this.provider_exapanded
          ? this.providers
          : this.providers.slice(0, 7);
      }
    },
    selectedProvider() {
      EventBus.$emit("selectedProvider", this.selectedProvider);
    },
    isHeader(value) {
      this.providerHolder = this.providers;
    },
  },
  mounted() {
    if (this.$vuetify.breakpoint.mobile || this.isHeader) {
      this.providerHolder = this.providers;
    } else {
      this.providerHolder = this.provider_exapanded
        ? this.providers
        : this.providers.slice(0, 7);
    }
    this.selectedProvider = [];
  },
  methods: {
    // async providerMove(index, newIndex) {
    //   let temp = this.providerHolder[index];
    //   this.providerHolder.splice(index, 1);
    //   this.providerHolder.splice(newIndex, 0, temp);

    //   this.$gameApi.post("/admin/casino/provider/order", {
    //     providers: this.providerHolder.map((e) => e.provider),
    //     game_type: this.type,
    //     newIndex,
    //     oldIndex: index,
    //   });
    // },
    expandProviderList() {
      this.provider_exapanded = !this.provider_exapanded;
      this.providerHolder = this.provider_exapanded
        ? this.providers
        : this.providers.slice(0, 7);
    },
    selectProvider(item) {
      if (this.selectedProvider.includes(item)) {
        const index = this.selectedProvider.indexOf(item);
        if (index > -1) {
          this.selectedProvider.splice(index, 1);
        }
      } else {
        this.selectedProvider.push(item);
      }
      // this.selectedProvider = item;
    },
    onDragStart: function (evt) {
      console.log("start: ", evt.item._underlying_vm_);
    },
    onDragEnd: function (evt) {
      console.log("temp: ", evt);

      // this.providerHolder.splice(evt.oldIndex, 1);
      // this.providerHolder.splice(evt.newIndex, 0, temp);
      this.$gameApi.post("/admin/casino/provider/order", {
        providers: this.providerHolder.map((e) => e.provider),
        game_type: this.type,
        newIndex: evt.newIndex + 1,
        oldIndex: evt.oldIndex + 1,
      });
    },

    checkMove: function (evt) {
      return evt.draggedContext.element !== "All"; // Prevent dragging "All"
    },
  },
  created() {
    EventBus.$on("selectedProvider", (provider) => {
      this.selectedProvider = provider;
    });
  },
};
</script>
<style scoped>
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
